import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import ReduxToastr from 'react-redux-toastr';

import RootReducer from './redux/rootReducer';

import AuthorizedRoute from './route/AuthorizedRoute';
import UnauthorizedLayout from './layouts/UnauthorizedLayout';
import Layout from './layouts/Layout';

// scroll to top after moving to other pages (e.g. after clicking a view details link)
import ScrollToTop from './layouts/ScrollToTop';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const middleware = [logger, thunk];

const store = createStore(
  RootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware)),
);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route path="/auth" component={UnauthorizedLayout} />
            <AuthorizedRoute path="/app" component={Layout} />
            <Redirect to="/app" />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </Provider>
  );
}

export default App;
