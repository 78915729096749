/* eslint-disable no-underscore-dangle */
import { API_SENSOR_URL, API_ANALYSIS_URL, buildHeader, getUserName } from '../../api/api';

export const GET_SITES = 'GET_SITES';
export const GET_SITE = 'GET_SITE';
export const RESET_SITE = 'RESET_SITE';
export const SAVE_STREAM = 'SAVE_STREAM';
export const SAVE_DOCUMENT = 'SAVE_DOCUMENT';
export const SAVE_SITE = 'SAVE_SITE';
export const RUN_SITE = 'RUN_SITE';
export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export const ERROR = 'ERROR';
export const RESET_ERROR = 'RESET_ERROR';

const getJob = async (workflowId) => {
  const res = await fetch(`${API_ANALYSIS_URL}/jobs?limit=1&workflowid=${workflowId}`, buildHeader());
  const json = await res.json();
  const { jobs } = json._embedded || { jobs: [] };
  return jobs.length === 0 ? null : jobs[0];
};

const withJobs = async workflows => Promise.all(
  workflows.map(async (workflow) => {
    const job = await getJob(workflow.id);
    console.log(job);
    return {
      ...workflow,
      job,
    };
  }),
);


const formatWorkflow = (wf) => {
  const json = JSON.parse(wf.description);
  return {
    ...wf,
    ...json,
  };
};

const formatWorkflows = wfs => wfs.map(formatWorkflow);

export function getSites() {
  return async (dispatch) => {
    const username = await getUserName();
    const res = await fetch(`${API_ANALYSIS_URL}/workflows?description=*${username}*`, buildHeader());
    if (!res.ok) {
      dispatch({
        type: 'ERROR',
        message: 'Failed to load sites.',
        status: res.status,
        error: res.error,
      });
      return;
    }

    const json = await res.json();
    const workflows = formatWorkflows(json._embedded ? json._embedded.workflows || [] : []);

    dispatch({
      type: 'GET_SITES',
      data: await withJobs(workflows),
    });
  };
}

export function getSite(workflowId) {
  return async (dispatch) => {
    try {
      const res = await fetch(`${API_ANALYSIS_URL}/workflows/${workflowId}`, buildHeader());
      const workflow = await res.json();

      if (!res.ok) {
        return dispatch({
          type: 'ERROR',
          message: `The site ${workflowId} failed to load.`,
          status: res.status,
          error: res,
        });
      }

      workflow.job = await getJob(workflow.id);
      return dispatch({
        type: 'GET_SITE',
        data: formatWorkflow(workflow),
      });
    } catch (error) {
      return dispatch({
        type: 'ERROR',
        error,
      });
    }
  };
}

export function resetSite() {
  return {
    type: 'RESET_SITE',
  };
}

export function saveStream(streamId, data) {
  return async (dispatch) => {
    const exists = await fetch(`${API_SENSOR_URL}/streams/${streamId}`, {
      method: 'GET',
      ...buildHeader(),
    });

    if (exists.ok) {
      return dispatch({
        type: 'SAVE_STREAM',
        data: exists.json(),
      });
    }

    const res = await fetch(`${API_SENSOR_URL}/streams/${streamId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      ...buildHeader(),
    });

    if (!res.ok) {
      console.log(res);
      throw new Error('The site failed to install');
    }

    const stream = await res.json();

    return dispatch({
      type: 'SAVE_STREAM',
      data: stream,
    });
  };
}

export function saveDocument(documentId, data) {
  return async (dispatch) => {
    const exists = await fetch(`${API_ANALYSIS_URL}/documentnodes/${documentId}`, {
      method: 'GET',
      ...buildHeader(),
    });

    if (exists.ok) {
      return dispatch({
        type: 'SAVE_DOCUMENT',
        data: exists.json(),
      });
    }

    const res = await fetch(`${API_ANALYSIS_URL}/documentnodes/${documentId}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      ...buildHeader(),
    });

    if (!res.ok) {
      console.log(res);
      throw new Error('The site failed to install');
    }

    const document = await res.json();

    return dispatch({
      type: 'SAVE_DOCUMENT',
      data: document,
    });
  };
}

export function saveSite(data) {
  return async (dispatch) => {
    let res = null;
    if (data.id) {
      res = await fetch(`${API_ANALYSIS_URL}/workflows/${data.id}?`, {
        method: 'PUT',
        body: JSON.stringify(data),
        ...buildHeader(),
      });
    } else {
      res = await fetch(`${API_ANALYSIS_URL}/workflows?`, {
        method: 'POST',
        body: JSON.stringify(data),
        ...buildHeader(),
      });
    }

    if (!res.ok) {
      console.log(res);
      throw new Error('The site failed to install');
    }

    const workflow = await res.json();
    return dispatch({
      type: 'SAVE_SITE',
      data: workflow,
    });
  };
}

export function runSite(data) {
  return async (dispatch) => {
    const res = await fetch(`${API_ANALYSIS_URL}/jobs?`, {
      method: 'POST',
      body: JSON.stringify(data),
      ...buildHeader(),
    });
    const job = await res.json();
    return dispatch({
      type: 'RUN_SITE',
      data: job,
    });
  };
}

export function createSchedule(data) {
  return async (dispatch) => {
    const res = await fetch(`${API_ANALYSIS_URL}/schedules?`, {
      method: 'POST',
      body: JSON.stringify(data),
      ...buildHeader(),
    });
    const schedule = await res.json();
    return dispatch({
      type: 'CREATE_SCHEDULE',
      data: schedule,
    });
  };
}

export function resetError() {
  return {
    type: 'RESET_ERROR',
  };
}
