import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TimeInfo from '../elements/TimeInfo';

import { getSites } from '../../redux/sites/siteActions';

import Loading from '../elements/Loading';

import { getFarmsystemLabel } from '../../api/api';

const SitesListWrapper = styled.div`
  
`;

class SitesList extends Component {
  componentDidMount() {
    const { getSitesConnect } = this.props;
    getSitesConnect();
  }

  componentDidUpdate(prevProps) {
    const { sites, getSitesConnect } = this.props;
    const runningSites = sites.filter(s => s.job && !s.job.iscomplete);

    if (sites !== prevProps.sites && runningSites.length > 0) {
      setTimeout(getSitesConnect, 1000);
    }
  }

  render() {
    const { sites, isLoaded, match } = this.props;
    if (!isLoaded) return <Loading />;

    let noSitesMessage = null;
    if (sites.length === 0) {
      noSitesMessage = <p>No sites found</p>;
    }

    const renderJob = (job) => {
      if (job) {
        return job.endtime ? <TimeInfo time={job.endtime} /> : (
          <>
            {job.status}
          </>
        );
      }

      return null;
    };

    return (
      <SitesListWrapper>
        <div className="card">
          <div className="card-header">
            Track My Site
          </div>
          <div className="card-body table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Site</th>
                  <th scope="col">Location (lon/lat)</th>
                  <th scope="col">Farm System</th>
                  <th scope="col">Latest Update</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sites.map(site => (
                  <tr key={site.id}>
                    <td>
                      <Link to={`${match.path}/sites/detail/${site.id}`}><b>{site.name}</b></Link>
                    </td>
                    <td>
                      <span>
                        {site.longitude}
                        ,
                        {site.latitude}
                      </span>
                    </td>
                    <td>
                      <span>
                        {getFarmsystemLabel(site.farm_system)}
                      </span>
                    </td>
                    <td>
                      {renderJob(site.job)}
                    </td>
                    <td>
                      <Link to={`${match.path}/sites/detail/${site.id}`}>
                        <span>View&nbsp;Results</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="6">
                    {noSitesMessage}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </SitesListWrapper>
    );
  }
}

const mapStateToProps = state => ({
  sites: state.sites.sites,
  isLoaded: state.sites.sitesLoaded,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSitesConnect: getSites,
}, dispatch);

SitesList.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  sites: PropTypes.oneOfType([
    PropTypes.array,
  ]).isRequired,
  isLoaded: PropTypes.bool.isRequired,
  getSitesConnect: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SitesList));
