import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getSite, resetSite, resetError } from '../redux/sites/siteActions';

import SiteSummary from '../components/site-detail/SiteSummary';
import Chart from '../components/site-detail/Chart';
import Loading from '../components/elements/Loading';

class SiteDetail extends Component {
  componentDidMount() {
    const { getSiteConnect, match } = this.props;
    getSiteConnect(match.params.id);
  }

  componentDidUpdate(prevProps) {
    const { site, getSiteConnect, match } = this.props;

    if (site !== prevProps.site && site.job && !site.job.iscomplete) {
      setTimeout(() => getSiteConnect(match.params.id), 1000);
    }
  }

  componentWillUnmount() {
    const { resetSiteConnect, resetErrorConnect } = this.props;
    resetSiteConnect();
    resetErrorConnect();
  }

  render() {
    const {
      site,
      isLoaded,
      message,
    } = this.props;

    if (message) {
      console.error(message);
      return (
        <p>
          <b>Your site failed to load.</b>
          <div className="text-muted">You may not have permission to view this page.</div>
        </p>
      );
    }

    if (!isLoaded) return <Loading />;

    return (
      <div>
        <Link className="mb-2 nav-link" to="/app">&lt; Back</Link>
        <SiteSummary site={site} />
        <div className="mt-3">
          <Chart site={site} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  site: state.sites.site,
  isLoaded: state.sites.siteLoaded,
  message: state.sites.message,
  status: state.sites.status,
  error: state.sites.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSiteConnect: getSite,
  resetSiteConnect: resetSite,
  resetErrorConnect: resetError,
}, dispatch);

SiteDetail.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  site: PropTypes.shape().isRequired,
  message: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired,
  getSiteConnect: PropTypes.func.isRequired,
  resetSiteConnect: PropTypes.func.isRequired,
  resetErrorConnect: PropTypes.func.isRequired,
};

SiteDetail.defaultProps = {
  message: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetail);
