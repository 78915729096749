import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { getUserName } from '../api/api';
import { logout } from '../redux/login/loginActions';

import csiroLogo from '../images/csiro-logo.svg';
import logo from '../images/pasture-tracker-logo.png';

const HeaderWrapper = styled.div`
  background-color: #007bff;
  .csiro-logo {
    width: 70px;
  }
  .logo {
    width: 120px;
  }
`;

class Header extends Component {
  handleLogOut = () => {
    const { logoutConnect } = this.props;
    logoutConnect();
  }

  render() {
    const { match } = this.props;
    const userName = getUserName();

    return (
      <HeaderWrapper>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <NavLink className="navbar-brand" exact to={`${match.path}`} activeClassName="active">
              <img className="csiro-logo" src={csiroLogo} alt="CSIRO Logo" />
              <img className="logo" src={logo} alt="Pasture Tracker Logo" />
              Pasture Tracker
            </NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarToggler">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" exact to={`${match.path}`} activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" exact to={`${match.path}/profile`} activeClassName="active">
                    Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" exact to={`${match.path}/guide`} activeClassName="active">
                    Guide
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" exact to={`${match.path}/contact`} activeClassName="active">
                    Contact
                  </NavLink>
                </li>
              </ul>
              <span className="text-light bg-success p-2 mr-2">
                {userName}
              </span>
              <button className="btn btn-outline-light my-2 my-sm-0" type="button" onClick={this.handleLogOut}>
                Log out
              </button>
            </div>
          </nav>
        </div>
      </HeaderWrapper>
    );
  }
}

const mapStateToProps = state => ({
  isLoggingIn: state.login.isLoggingIn,
  isAuthenticated: state.login.isAuthenticated,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  logoutConnect: logout,
}, dispatch);

Header.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
  logoutConnect: PropTypes.func.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
