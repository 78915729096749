import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

function TimeInfo(props) {
  const { time } = props;
  return (
    <div>
      {time && (
        <>
          <div className="text-nowrap">
            {moment(time).startOf('minute').fromNow()}
          </div>
          <i className="small text-muted text-nowrap">{moment(time).format('YYYY-MM-DD HH:mm Z')}</i>
        </>
      )
      }
    </div>
  );
}

TimeInfo.propTypes = {
  time: PropTypes.string,
};

TimeInfo.defaultProps = {
  time: '',
};

export default TimeInfo;
