/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import echarts from 'echarts';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getFarmsystemLabel } from '../../api/api';
import { getObservations, getDocumentContent } from '../../redux/chart/chartActions';
import { MAPPINGS } from '../../data/constants';

import Loading from '../elements/Loading';

const ChartWrapper = styled.div`
  .chart {
    height: 70vw;
    max-height: 500px
  }
`;

class Chart extends Component {
  state = {
    isLoaded: false,
    charts: [],
  }

  componentDidMount() {
    const { site } = this.props;

    if (!site.job) {
      this.setState({
        isLoaded: true,
      });
    } else if (site.job && site.job.iscomplete) {
      this.renderCharts();
    }
  }

  componentDidUpdate(prevProps) {
    const { site } = this.props;

    if (site !== prevProps.site && site.job && site.job.iscomplete) {
      this.renderCharts();
    }
  }

  renderCharts = async () => {
    const { site } = this.props;
    
    const charts = await this.buildSeries();
    console.log(charts);
    // eslint-disable-next-line no-restricted-syntax
    for (const [variable, chart] of Object.entries(charts)) {
      this.renderChart(variable, chart, site);

      this.setState(prevState => ({
        charts: [
          ...prevState.charts,
          { hasData: true, variable },
        ],
      }));
    }
  }

  buildSeries = async () => {
    const charts = [];
    
    await Promise.all(MAPPINGS.map(async (mapping) => {
      const { variable, mode, type } = mapping;
      const nodeId = `${variable}.${mode}.${type}_node`;

      if (!charts[variable]) {
        charts[variable] = { series: [], cdf: null };
      }

      if (mode === 'historic' && type === 'percentiles') {
        const historicVector = await this.getObservationsResults(nodeId);
        const historicData = [];

        for (let i = 0; i < 5; i += 1) {
          // i < 5 (for each percentile value)
          // 1 year in 31556952000 millis
          historicData.push(this.createData(historicVector, i, null).concat(this.createData(historicVector, i, 31556952000)));
        }

        if (historicVector.length > 0) {
          charts[variable].series = [{
            name: `${variable}_historic_pctile_0.9`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: '#17A768',
            },
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: '#17A768',
              opacity: 0.5,
            },
            data: historicData[historicData.length - 1],
          },
          {
            name: `${variable}_historic_pctile_0.5`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: '#C2EBD4',
            },
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: '#C2EBD4',
              opacity: 0.5,
            },
            data: historicData[Math.floor(historicData.length / 2)],
          },
          {
            name: `${variable}_historic_pctile_0.1`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: '#FFFFFF',
            },
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: '#FFFFFF',
              opacity: 1,
            },
            data: historicData[0],
          }].concat(charts[variable].series);
        }
      } else if (mode === 'tactical' && type === 'percentiles') {
        const tacticalVector = await this.getObservationsResults(nodeId);
        const tacticalData = [];

        for (let i = 0; i < 5; i += 1) {
          // 5 percentile values
          tacticalData.push(this.createData(tacticalVector, i));
        }

        if (tacticalData.length > 0) {
          charts[variable].series = [{
            name: '90%',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'blue',
            },
            lineStyle: {
              width: 3,
              color: 'blue',
            },
            data: tacticalData[tacticalData.length - 1],
          },
          {
            name: 'Median',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'yellow',
            },
            lineStyle: {
              width: 3,
              color: 'yellow',
            },
            data: tacticalData[Math.floor(tacticalData.length % 2)],
          },
          {
            name: '10%',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'red',
            },
            lineStyle: {
              width: 3,
              color: 'red',
            },
            data: tacticalData[0],
          }].concat(charts[variable].series);
        }
      } else if (mode === 'spinup' && type === 'actual') {
        const spinupScalar = await this.getObservationsResults(nodeId);
        const spinupScalarData = this.createData(spinupScalar);

        if (spinupScalarData.length > 0) {
          charts[variable].series = [{
            name: `${variable}_spinup`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'black',
            },
            lineStyle: {
              type: 'dotted',
              color: 'black',
            },
            data: spinupScalarData,
          }].concat(charts[variable].series);
        }
      } else if (mode === 'tracking' && type === 'actual') {
        const trackingScalar = await this.getObservationsResults(nodeId);
        const trackingScalarData = this.createData(trackingScalar, 0);
        if (trackingScalarData.length > 0) {
          charts[variable].series = [{
            name: 'Tracking',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'black',
            },
            lineStyle: {
              width: 5,
              color: 'black',
            },
            data: trackingScalarData,
          }].concat(charts[variable].series);
        }
      } else if (mode === 'tactical' && type === 'cdf') {
        try {
          const cdfDocument = await this.getDocumentContentResults(nodeId);
          const cdfJson = cdfDocument;

          // format { "columns": ["a", "b", "c"], "index": [1,2,3], "data": [[0,1,2], [null, 1, 10]] }

          const plots = cdfJson.columns.map((col, idx) => {
            const values = cdfJson.index.filter((probability, row) => !Number.isNaN(parseInt(cdfJson.data[row][idx], 10))).map((probability, row) => [cdfJson.data[row][idx], probability]);
            
            return {
              name: col,
              type: 'line',
              symbol: 'circle',
              data: values,
            };
          }).filter(plot => plot.data.length > 0);

          charts[variable].cdf = { index: cdfJson.index, columns: cdfJson.columns, series: plots };
        } catch (e) {
          console.log(`Failed to parse cdf as json: ${e}`);

          return;
        }
      }
    }));

    return charts;
  }

  renderChart = async (variable, chart, site) => {
    await this.setState({
      isLoaded: true,
    });

    const waterMarkText = 'CSIRO';

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = 400;
    canvas.height = 400;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.globalAlpha = 0.04;
    ctx.font = '40px Arial';
    ctx.translate(50, 50);
    ctx.rotate(-Math.PI / 4);
    ctx.fillText(waterMarkText, 0, 0);

    const myCharts = echarts.init(document.getElementById(variable));
    const farmSystem = getFarmsystemLabel(site.farm_system);
    
    window.addEventListener('resize', () => {
      myCharts.resize();
    });
    
    const options = {
      backgroundColor: {
        type: 'pattern',
        image: canvas,
        repeat: 'repeat',
      },
      title: {
        text: variable,
        subtext: `Pasture Tracker Site: ${site.name} - location (lon/lat): ${site.longitude},${site.latitude} - ${farmSystem}`,
        padding: [10, 10],
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      grid: {
        top: 100, left: 50, right: 10,
      },
      dataZoom: [{ type: 'slider' }, { type: 'inside' }],
      
      toolbox: {
        right: 20,
        feature: {
          saveAsImage: {
            title: 'Save image',
            name: site.name,
          },
        },
      },
    };

    if (chart.series.length > 0) {
      options.legend = {
        right: 10,
        top: 40,
        data: ['10%', 'Median', '90%', 'Tracking'],
      };

      options.xAxis = {
        type: 'time',
        axisLabel: {
          formatter(value) {
            const date = new Date(value);
            const label = `${date.getMonth() + 1}/${date.getFullYear()}`;
            return label;
          },
        },
      };

      options.yAxis = {
        name: '',
        type: 'value',
      };
      
      options.series = chart.series;

      myCharts.setOption(options);
    }

    if (chart.cdf) {
      options.legend = {
        right: 10,
        top: 40,
        data: chart.cdf.columns,
      };

      options.yAxis = {
        type: 'value',
        data: chart.cdf.index,
      };

      options.xAxis = {
        type: 'value',
      };

      options.series = chart.cdf.series;

      myCharts.setOption(options);
    }
  }

  searchStreamId = (nodeId) => {
    const { site } = this.props;
    const searchNode = site._embedded.graph._embedded.nodes.filter(node => node.id === nodeId);

    if (searchNode.length !== 1) {
      return null;
    }

    return searchNode[0].streamid;
  }

  searchDocumentId = (nodeId) => {
    const { site } = this.props;
    const searchNode = site._embedded.graph._embedded.nodes.filter(node => node.id === nodeId);

    if (searchNode.length !== 1) {
      return null;
    }

    return searchNode[0].documentid;
  }

  getObservationsResults = async (nodeId, startDate) => {
    const { getObservationsConnect } = this.props;
    const streamId = this.searchStreamId(nodeId);

    await getObservationsConnect(streamId, startDate);
    const { observations } = this.props;

    return Object.prototype.hasOwnProperty.call(observations, 'results') ? observations.results : [];
  }

  getDocumentContentResults = async (nodeId) => {
    const { getDocumentContentConnect } = this.props;
    const documentId = this.searchDocumentId(nodeId);

    await getDocumentContentConnect(documentId);
    const { content } = this.props;

    return content;
  }

  createData = (observationsResults, index, offsetMillis) => {
    const observations = [];

    if (observationsResults.length > 0) {
      const lastObservation = observationsResults[observationsResults.length - 1];
      const lastObservationYear = new Date(Date.parse(lastObservation.t)).getFullYear();
      observationsResults.filter(o => o.t.indexOf(lastObservationYear) >= 0).forEach((observation) => {
        const chartData = {
          value: [],
        };

        if (observation.t && observation.v && observation.v.v !== undefined) {
          chartData.value[0] = offsetMillis ? new Date(Date.parse(observation.t) + offsetMillis) : observation.t;
          chartData.value[1] = observation.v.v[index] !== undefined
            ? observation.v.v[index] : observation.v.v;
          observations.push(chartData);
        }
      });
    }
    return observations;
  }

  constructChart = async (reportType, title, displayOrder) => {
    const { site } = this.props;

    const historicVector = await this.getObservationsResults(`${reportType}_historic`);
    const historicData = [];

    for (let i = 0; i < 5; i += 1) {
      // 1 year in 31556952000 millis
      historicData.push(this.createData(historicVector, i, null).concat(this.createData(historicVector, i, 31556952000)));
    }

    const isData = historicVector.length > 0;
    this.setState(prevState => ({
      charts: [
        ...prevState.charts,
        { hasData: isData, reportType, displayOrder },
      ],
    }));

    const tacticalVector = await this.getObservationsResults(`${reportType}_tactical`);
    const tacticalData = [];

    for (let i = 0; i < 5; i += 1) {
      tacticalData.push(this.createData(tacticalVector, i));
    }

    const trackingScalar = await this.getObservationsResults(`${reportType}_tracking`);
    const trackingScalarData = this.createData(trackingScalar, 0);

    const startOfForecastingYear = isData ? historicData[0][0].value[0] : undefined;
    const spinupScalar = await this.getObservationsResults(`${reportType}_spinup`, startOfForecastingYear);
    const spinupScalarData = this.createData(spinupScalar);

    await this.setState({
      isLoaded: true,
    });

    if (isData) {
      const waterMarkText = 'CSIRO';

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = 400;
      canvas.height = 400;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.globalAlpha = 0.04;
      ctx.font = '40px Arial';
      ctx.translate(50, 50);
      ctx.rotate(-Math.PI / 4);
      ctx.fillText(waterMarkText, 0, 0);

      const myCharts = echarts.init(document.getElementById(reportType));
      const farmSystem = getFarmsystemLabel(site.farm_system);
      
      myCharts.setOption({
        backgroundColor: {
          type: 'pattern',
          image: canvas,
          repeat: 'repeat',
        },
        title: {
          text: title,
          subtext: `Pasture Tracker Site: ${site.name} - location (lon/lat): ${site.longitude},${site.latitude} - ${farmSystem}`,
          padding: [10, 10],
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        grid: {
          top: 100, left: 50, right: 10,
        },
        dataZoom: [{ type: 'slider' }, { type: 'inside' }],
        legend: {
          right: 10,
          top: 40,
          data: ['10%', 'Median', '90%', 'Tracking'],
        },
        toolbox: {
          right: 20,
          feature: {
            saveAsImage: {
              title: 'Save image',
              name: site.name,
            },
          },
        },
        xAxis: {
          type: 'time',
          axisLabel: {
            formatter(value) {
              const date = new Date(value);
              const label = `${date.getMonth() + 1}/${date.getFullYear()}`;
              return label;
            },
          },
        },
        yAxis: {
          name: '',
          type: 'value',
        },
        series: [
          {
            name: `${reportType}_historic_pctile_0.9`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: '#17A768',
            },
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: '#17A768',
              opacity: 0.5,
            },
            data: historicData[historicData.length - 1],
          },
          {
            name: `${reportType}_historic_pctile_0.5`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: '#C2EBD4',
            },
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: '#FFFFFF',
              opacity: 0.5,
            },
            data: historicData[Math.floor(historicData.length / 2)],
          },
          {
            name: `${reportType}_historic_pctile_0.1`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: '#FFFFFF',
            },
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: '#FFFFFF',
              opacity: 1,
            },
            data: historicData[0],
          },
          {
            name: '90%',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'blue',
            },
            lineStyle: {
              width: 3,
              color: 'blue',
            },
            data: tacticalData[tacticalData.length - 1],
          },
          {
            name: 'Median',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'yellow',
            },
            lineStyle: {
              width: 3,
              color: 'yellow',
            },
            data: tacticalData[Math.floor(tacticalData.length % 2)],
          },
          {
            name: '10%',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'red',
            },
            lineStyle: {
              width: 3,
              color: 'red',
            },
            data: tacticalData[0],
          },
          {
            name: 'Tracking',
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'black',
            },
            lineStyle: {
              width: 5,
              color: 'black',
            },
            data: trackingScalarData,
          },
          {
            name: `${reportType}_spinup`,
            type: 'line',
            symbol: 'none',
            itemStyle: {
              color: 'black',
            },
            lineStyle: {
              type: 'dotted',
              color: 'black',
            },
            data: spinupScalarData,
          },
        ],
      });

      window.addEventListener('resize', () => {
        myCharts.resize();
      });
    }
  }

  render() {
    const { isLoaded, charts } = this.state;
    
    return (
      <ChartWrapper>
        <div className="card">
          <div className="card-header">
            Results
          </div>
          {isLoaded ? (
            charts.map(chart => (
              <div key={chart.variable} className="card-body">
                {chart.hasData
                  ? <div id={chart.variable} className="chart" />
                  : <p>No data found</p>}
              </div>
            ))
          ) : (
            <div className="card-body">
              <p>Loading...</p>
              <Loading />
            </div>
          )}
        </div>
      </ChartWrapper>
    );
  }
}

const mapStateToProps = state => ({
  observations: state.chart.observations,
  content: state.chart.content,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getObservationsConnect: getObservations,
  getDocumentContentConnect: getDocumentContent,
}, dispatch);

Chart.propTypes = {
  site: PropTypes.shape().isRequired,
  observations: PropTypes.shape().isRequired,
  content: PropTypes.string.isRequired,
  getObservationsConnect: PropTypes.func.isRequired,
  getDocumentContentConnect: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart);
