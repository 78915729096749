import React, { PureComponent } from 'react';
import styled from 'styled-components';

// this is for a sticky footer
const FooterWrapper = styled.div`
  flex-shrink: 0;
`;

class Footer extends PureComponent {
  render() {
    return (
      <FooterWrapper>
        <footer className="p-3 bg-dark text-white text-center">
          &copy;&nbsp;
          {new Date().getFullYear()}
          &nbsp;Pasture Tracker
        </footer>
      </FooterWrapper>
    );
  }
}

export default Footer;
