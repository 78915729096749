import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
} from './loginActions';

const initialState = {
  // If we have a user in local storage, the initial state should be isLoggingIn = true.
  // We just need to verify the user is valid.
  // We set the initial state to avoid
  // having a quick flash of the login screen while we reAuthenticate

  isLoggingIn: typeof window !== 'undefined' ? Boolean(window.localStorage.getItem('user')) : false,
  isAuthenticated: false,
  error: null,
};

export default function (state = initialState, action) {
  const { type, error } = action;
  switch (type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        isAuthenticated: false,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        error,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
