export const API_SENSOR_URL = 'https://senaps.io/api/sensor/v2';
export const API_ANALYSIS_URL = 'https://senaps.io/api/analysis';

const getUser = () => JSON.parse(localStorage.getItem('user')) || null;

export const getUserName = () => {
  const user = getUser();
  return user ? user.username : '';
};

export const getUserToken = () => {
  const user = getUser();
  return user ? user.token : '';
};

export const buildHeader = (token = getUserToken()) => ({
  headers: {
    Authorization: `Basic ${token}`,
    'Content-Type': 'application/json',
  },
});

export const isTokenValid = async (token) => {
  const options = buildHeader(token);
  const response = await fetch(`${API_SENSOR_URL}/`, options);
  const body = await response.json();
  return { response, body };
};

export const AVAILABLE_FARM_SYSTEMS = [
  {
    label: 'srFineMerino',
    value: 'srFineMerino',
  },
  {
    label: 'srMerino',
    value: 'srMerino',
  },
  {
    label: 'srMerinoStubbleShrub',
    value: 'srMerinoStubbleShrub',
  },
  {
    label: 'srSuffolk',
    value: 'srSuffolk',
  },
  {
    label: 'wethersMerino',
    value: 'wethersMerino',
  },
  {
    label: 'wethersMerinoStubble',
    value: 'wethersMerinoStubble',
  },
  {
    label: 'srAngus',
    value: 'srAngus',
  },
  {
    label: 'steersAngus',
    value: 'steersAngus',
  },
];

export const getFarmsystemLabel = (value) => {
  const matches = AVAILABLE_FARM_SYSTEMS.filter(fs => fs.value === value);

  if (matches.length === 1) {
    return matches[0].label;
  }

  return value;
};
