import { combineReducers } from 'redux';

import { reducer as toastrReducer } from 'react-redux-toastr';

import sites from './sites/siteReducer';
import login from './login/loginReducer';
import chart from './chart/chartReducer';

const rootReducer = combineReducers({
  sites,
  login,
  chart,
  toastr: toastrReducer,
});

export default rootReducer;
