import { API_SENSOR_URL, buildHeader, API_ANALYSIS_URL } from '../../api/api';

export const GET_OBSERVATIONS = 'GET_OBSERVATIONS';
export const GET_DOCUMENT_CONTENT = 'GET_DOCUMENT_CONTENT';

export function getObservations(streamId, startDate) {
  return async (dispatch) => {
    const res = await fetch(`${API_SENSOR_URL}/observations?streamid=${streamId}&limit=1000000&si=true&start=${startDate || ''}`, buildHeader());
    const observations = await res.json();
    return dispatch({
      type: GET_OBSERVATIONS,
      data: observations,
    });
  };
}

export function getDocumentContent(documentId) {
  return async (dispatch) => {
    const res = await fetch(`${API_ANALYSIS_URL}/documentnodes/${documentId}/value`, buildHeader());
    const content = await res.json();
    return dispatch({
      type: GET_DOCUMENT_CONTENT,
      data: content,
    });
  };
}
