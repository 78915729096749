import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';

import SiteAll from '../pages/SiteAll';
import SiteDetail from '../pages/SiteDetail';
import Profile from '../pages/Profile';
import Guide from '../pages/Guide';
import Contact from '../pages/Contact';

// this is for a sticky footer
const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

// this is for a sticky footer
const Content = styled.div`
  flex: 1 0 auto;
`;

const Layout = ({ match }) => (
  <LayoutWrapper>
    <Header />
    <Content>
      <div className="container text-dark p-3">
        <Switch>
          <Route exact path={`${match.path}`} component={SiteAll} />
          <Route exact path={`${match.path}/sites/detail/:id`} component={SiteDetail} />
          <Route exact path={`${match.path}/profile`} component={Profile} />
          <Route exact path={`${match.path}/guide`} component={Guide} />
          <Route exact path={`${match.path}/contact`} component={Contact} />
          <Redirect to={`${match.path}`} />
        </Switch>
      </div>
    </Content>
    <Footer />
  </LayoutWrapper>
);

Layout.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default Layout;
