import {
  GET_SITES,
  GET_SITE,
  RESET_SITE,
  SAVE_STREAM,
  SAVE_DOCUMENT,
  SAVE_SITE,
  RUN_SITE,
  CREATE_SCHEDULE,
  ERROR,
  RESET_ERROR,
} from './siteActions';

const initialState = {
  sites: [],
  sitesLoaded: false,
  site: {},
  siteLoaded: false,
  newStream: {},
  newDocument: {},
  newSite: {},
  newJob: {},
  newSchedule: {},
  message: null,
  status: null,
  error: null,
};

export default function (state = initialState, action) {
  const {
    type,
    data,
    message,
    status,
    error,
  } = action;
  switch (type) {
    case GET_SITES:
      return {
        ...state,
        sites: data,
        sitesLoaded: true,
      };
    case GET_SITE:
      return {
        ...state,
        site: data,
        siteLoaded: true,
      };
    case RESET_SITE:
      return {
        ...state,
        site: {},
        siteLoaded: false,
      };
    case SAVE_STREAM:
      return {
        ...state,
        newStream: data,
      };
    case SAVE_DOCUMENT:
      return {
        ...state,
        newDocument: data,
      };
    case SAVE_SITE:
      return {
        ...state,
        newSite: data,
      };
    case RUN_SITE:
      return {
        ...state,
        newJob: data,
      };
    case CREATE_SCHEDULE:
      return {
        ...state,
        newSchedule: data,
      };
    case ERROR:
      return {
        ...state,
        message,
        status,
        error,
      };
    case RESET_ERROR:
      return {
        ...state,
        message: null,
        status: null,
        error: null,
      };
    default:
      return state;
  }
}
