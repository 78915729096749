import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { login } from '../redux/login/loginActions';

import logo from '../images/pasture-tracker-logo.png';
import csiroLogo from '../images/csiro-logo.svg';

const LoginWrapper = styled.div`
  text-align: center;
  header {
    background: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 200px;
    pointer-events: none;
  }
  .error-box {
    width: 200px;
  }
`;

class Login extends Component {
  handleSubmit = async (event) => {
    event.preventDefault();
    const { loginConnect, history } = this.props;

    await loginConnect(this.email.value, this.password.value);
    const { loginError } = await this.props;
    if (!loginError) await history.push('/app');
  }

  renderLoginError = () => {
    const { loginError } = this.props;
    if (!loginError) return null;

    console.error('Developer message: Login failed: ', loginError);

    return (
      <span className="text-danger">
        Error logging in. Please check your email and password is correct.
      </span>
    );
  }

  render() {
    return (
      <LoginWrapper>
        <header>
          <img src={csiroLogo} alt="CSIRO Logo" />
          <img src={logo} alt="Pasture Tracker Logo" />
          <p className="text-secondary mb-3">
            Pasture Tracker
          </p>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input className="form-control" type="text" name="email" placeholder="Email" ref={(input) => { this.email = input; }} required />
            </div>
            <div className="form-group mb-3">
              <input className="form-control" type="password" name="password" placeholder="Password" ref={(input) => { this.password = input; }} autoComplete="off" required />
            </div>
            <div className="form-group error-box">
              {this.renderLoginError()}
            </div>
            <div className="form-group">
              <a href="https://sensor-cloud.io/dashboard/#/access/resetreq">
                Forgot password?
              </a>
            </div>
            <div className="form-group">
              <button className="btn btn-primary" type="submit">Log in</button>
            </div>
          </form>
        </header>
      </LoginWrapper>
    );
  }
}

const mapStateToProps = state => ({
  isLoggingIn: state.login.isLoggingIn,
  isAuthenticated: state.login.isAuthenticated,
  loginError: state.login.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  loginConnect: login,
}, dispatch);

Login.propTypes = {
  loginError: PropTypes.shape(),
  history: PropTypes.shape().isRequired,
  loginConnect: PropTypes.func.isRequired,
};

Login.defaultProps = {
  loginError: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
