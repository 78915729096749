// eslint-disable-next-line import/prefer-default-export
export const MAPPINGS = [{ variable: 'available_green_herbage', mode: 'historic', type: 'percentiles' },
  { variable: 'available_green_herbage', mode: 'tactical', type: 'percentiles' },
  { variable: 'available_green_herbage', mode: 'spinup', type: 'actual' },
  { variable: 'available_green_herbage', mode: 'tracking', type: 'actual' },
  { variable: 'average_ground_cover', mode: 'historic', type: 'percentiles' },
  { variable: 'average_ground_cover', mode: 'tactical', type: 'percentiles' },
  { variable: 'average_ground_cover', mode: 'spinup', type: 'actual' },
  { variable: 'average_ground_cover', mode: 'tracking', type: 'actual' },
  { variable: 'condition_score_female_cattle_mature', mode: 'historic', type: 'percentiles' },
  { variable: 'condition_score_female_cattle_mature', mode: 'tactical', type: 'percentiles' },
  { variable: 'condition_score_female_cattle_mature', mode: 'spinup', type: 'actual' },
  { variable: 'condition_score_female_cattle_mature', mode: 'tracking', type: 'actual' },
  { variable: 'condition_score_female_sheep_mature', mode: 'historic', type: 'percentiles' },
  { variable: 'condition_score_female_sheep_mature', mode: 'tactical', type: 'percentiles' },
  { variable: 'condition_score_female_sheep_mature', mode: 'spinup', type: 'actual' },
  { variable: 'condition_score_female_sheep_mature', mode: 'tracking', type: 'actual' },
  { variable: 'ground_cover', mode: 'tactical', type: 'cdf' },
  { variable: 'live_weight', mode: 'tactical', type: 'cdf' },
  { variable: 'live_weight_young_female_cattle_weaner', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_female_cattle_weaner', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_female_cattle_weaner', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_female_cattle_weaner', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_female_cattle_yearling', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_female_cattle_yearling', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_female_cattle_yearling', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_female_cattle_yearling', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_female_sheep_weaner', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_female_sheep_weaner', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_female_sheep_weaner', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_female_sheep_weaner', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_female_sheep_yearling', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_female_sheep_yearling', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_female_sheep_yearling', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_female_sheep_yearling', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_male_cattle_weaner', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_male_cattle_weaner', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_male_cattle_weaner', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_male_cattle_weaner', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_male_cattle_yearling', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_male_cattle_yearling', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_male_cattle_yearling', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_male_cattle_yearling', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_male_sheep_weaner', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_male_sheep_weaner', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_male_sheep_weaner', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_male_sheep_weaner', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_male_sheep_yearling', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_male_sheep_yearling', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_male_sheep_yearling', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_male_sheep_yearling', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_male_sheep_unweaned', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_male_sheep_unweaned', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_male_sheep_unweaned', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_male_sheep_unweaned', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_female_sheep_unweaned', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_female_sheep_unweaned', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_female_sheep_unweaned', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_female_sheep_unweaned', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_male_cattle_unweaned', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_male_cattle_unweaned', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_male_cattle_unweaned', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_male_cattle_unweaned', mode: 'tracking', type: 'actual' },
  { variable: 'live_weight_young_female_cattle_unweaned', mode: 'historic', type: 'percentiles' },
  { variable: 'live_weight_young_female_cattle_unweaned', mode: 'tactical', type: 'percentiles' },
  { variable: 'live_weight_young_female_cattle_unweaned', mode: 'spinup', type: 'actual' },
  { variable: 'live_weight_young_female_cattle_unweaned', mode: 'tracking', type: 'actual' },
  { variable: 'plant_available_water', mode: 'historic', type: 'percentiles' },
  { variable: 'plant_available_water', mode: 'tactical', type: 'percentiles' },
  { variable: 'plant_available_water', mode: 'spinup', type: 'actual' },
  { variable: 'plant_available_water', mode: 'tracking', type: 'actual' },
  { variable: 'supplement_intake', mode: 'tactical', type: 'cdf' },
  { variable: 'supplement_intake_cattle_weaner', mode: 'historic', type: 'percentiles' },
  { variable: 'supplement_intake_cattle_weaner', mode: 'tactical', type: 'percentiles' },
  { variable: 'supplement_intake_cattle_weaner', mode: 'spinup', type: 'actual' },
  { variable: 'supplement_intake_cattle_weaner', mode: 'tracking', type: 'actual' },
  { variable: 'supplement_intake_female_sheep_weaner', mode: 'historic', type: 'percentiles' },
  { variable: 'supplement_intake_female_sheep_weaner', mode: 'tactical', type: 'percentiles' },
  { variable: 'supplement_intake_female_sheep_weaner', mode: 'spinup', type: 'actual' },
  { variable: 'supplement_intake_female_sheep_weaner', mode: 'tracking', type: 'actual' },
  { variable: 'supplement_intake_male_sheep_weaner', mode: 'historic', type: 'percentiles' },
  { variable: 'supplement_intake_male_sheep_weaner', mode: 'tactical', type: 'percentiles' },
  { variable: 'supplement_intake_male_sheep_weaner', mode: 'spinup', type: 'actual' },
  { variable: 'supplement_intake_male_sheep_weaner', mode: 'tracking', type: 'actual' },
  { variable: 'total_green_herbage_mass', mode: 'historic', type: 'percentiles' },
  { variable: 'total_green_herbage_mass', mode: 'tactical', type: 'percentiles' },
  { variable: 'total_green_herbage_mass', mode: 'spinup', type: 'actual' },
  { variable: 'total_green_herbage_mass', mode: 'tracking', type: 'actual' },
  { variable: 'total_supplement_intake', mode: 'historic', type: 'percentiles' },
  { variable: 'total_supplement_intake', mode: 'tactical', type: 'percentiles' },
  { variable: 'total_supplement_intake', mode: 'spinup', type: 'actual' },
  { variable: 'total_supplement_intake', mode: 'tracking', type: 'actual' }];
