import { isTokenValid } from '../../api/api';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'LOGIN_REQUEST',
      });

      const emailLower = email.toLowerCase();
      const token = btoa(`${emailLower}:${password}`);
      const { response, body } = await isTokenValid(token);

      if (!response.ok) {
        dispatch({
          type: 'LOGIN_FAILURE',
          error: body,
        });
      } else {
        dispatch({
          type: 'LOGIN_SUCCESS',
        });
        localStorage.setItem('user', JSON.stringify({ token, username: emailLower }));
      }
    } catch (error) {
      dispatch({
        type: 'LOGIN_FAILURE',
        error,
      });
    }
  };
}

export function reAuthenticate(userToken) {
  return async (dispatch) => {
    try {
      dispatch({
        type: 'LOGIN_REQUEST',
      });

      const { response, body } = await isTokenValid(userToken);

      if (!response.ok) {
        throw new Error(body);
      } else {
        dispatch({
          type: 'LOGIN_SUCCESS',
        });
      }
    } catch (error) {
      dispatch({
        type: 'LOGIN_FAILURE',
        error,
      });
      localStorage.removeItem('user');
    }
  };
}

export function logout() {
  return async (dispatch) => {
    dispatch({
      type: 'LOGOUT_REQUEST',
    });
    localStorage.removeItem('user');
  };
}
