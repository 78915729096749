import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TimeInfo from '../elements/TimeInfo';
import { getFarmsystemLabel } from '../../api/api';

const SiteSummaryWrapper = styled.div`
  
`;

const renderJob = (job) => {
  if (job) {
    return job.endtime ? <TimeInfo time={job.endtime} /> : (
      <>
        {job.status}
      </>
    );
  }

  return null;
};

const SiteSummary = ({ site }) => (
  <SiteSummaryWrapper>
    <div className="card">
      <div className="card-header">
        <div>
          Site Information
        </div>
      </div>
      <div className="card-body">
        <dl className="row">
          <dt className="col-sm-2">Name</dt>
          <dd className="col-sm-10">
            <b>{site.name}</b>
          </dd>
          <dt className="col-sm-2">Location (lon/lat)</dt>
          <dd className="col-sm-10">
            {site.longitude}
            ,
            {site.latitude}
            <div>
              <a className="small" target="_blank" rel="noopener noreferrer" href={`https://www.google.com/maps/search/${site.latitude},${site.longitude}`}>View in Google maps (opens in new tab)</a>
            </div>
          </dd>
          <dt className="col-sm-2">Farm System</dt>
          <dd className="col-sm-10">
            {getFarmsystemLabel(site.farm_system)}
          </dd>
          <dt className="col-sm-2">Last Updated</dt>
          <dd className="col-sm-10">
            {renderJob(site.job)}
          </dd>
        </dl>
      </div>
    </div>
  </SiteSummaryWrapper>
);

SiteSummary.propTypes = {
  site: PropTypes.shape().isRequired,
};

export default SiteSummary;
