import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { getUserToken } from '../api/api';
import { reAuthenticate } from '../redux/login/loginActions';

import Loading from '../components/elements/Loading';

class AuthorizedRoute extends Component {
  componentWillMount() {
    const { reAuthenticateConnect } = this.props;
    const userToken = getUserToken();

    if (userToken) reAuthenticateConnect(userToken);
  }

  render() {
    const {
      component: LayoutComponent,
      isLoggingIn,
      isAuthenticated,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          if (isLoggingIn) return <Loading />;

          return isAuthenticated
            ? <LayoutComponent {...props} />
            : <Redirect to="/auth/login" />;
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoggingIn: state.login.isLoggingIn,
  isAuthenticated: state.login.isAuthenticated,
  error: state.login.error,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  reAuthenticateConnect: reAuthenticate,
}, dispatch);

AuthorizedRoute.propTypes = {
  isLoggingIn: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  error: PropTypes.shape(),
  reAuthenticateConnect: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired,
};

AuthorizedRoute.defaultProps = {
  error: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedRoute);
