import {
  GET_OBSERVATIONS, GET_DOCUMENT_CONTENT,
} from './chartActions';

const initialState = {
  observations: {},
  content: {},
};

export default function (state = initialState, action) {
  const { type, data } = action;
  switch (type) {
    case GET_OBSERVATIONS:
      return {
        ...state,
        observations: data,
      };
    case GET_DOCUMENT_CONTENT:
      return {
        ...state,
        content: data,
      };
    default:
      return state;
  }
}
