import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  getSites,
  saveStream,
  saveDocument,
  saveSite,
  runSite,
  createSchedule,
} from '../../redux/sites/siteActions';

import WORKFLOW_TEMPLATE from '../../data/grassgro_workflow_template';
import VECTOR_STREAM_TEMPLATE from '../../data/grassgro_vector_stream_template';
import SCALAR_STREAM_TEMPLATE from '../../data/grassgro_scalar_stream_template';
import DOCUMENT_TEMPLATE from '../../data/grassgro_document_template';
import JOB_TEMPLATE from '../../data/grassgro_job_template';
import SCHEDULE_TEMPLATE from '../../data/grassgro_schedule_template.json';
import { getUserName, AVAILABLE_FARM_SYSTEMS } from '../../api/api';
import { MAPPINGS } from '../../data/constants';

const SiteCreateWrapper = styled.div`
  
`;

class SiteCreate extends Component {
  // Controlled input - siteName, longitude and latitude
  // UnControlled input (using Refs) - farmingSystem
  // have not decided which method is better for this case.

  state = {
    siteName: '',
    longitude: '',
    latitude: '',
    isSubmitting: false,
  }

  handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    if (name === 'longitude') {
      if (value === '-' || Math.abs(value) <= 180) {
        this.setState({
          [name]: value.trim(),
        });
      }
    } else if (name === 'latitude') {
      if (value === '-' || Math.abs(value) <= 90) {
        this.setState({
          [name]: value.trim(),
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  replaceNamesInWorkflow = (workflowTemplate, siteName, longitude, latitude) => {
    const now = moment();
    const startOfForecast = moment(now).startOf('month');
    const endOfForecast = moment(startOfForecast).add(3, 'months').endOf('month');

    const forecastYear = moment(startOfForecast).format('YYYY');

    const historicStart = moment(new Date(forecastYear - 30, 0, 1)).format('YYYY-MM-DD');
    const historicEnd = moment(new Date(forecastYear - 1, 11, 31)).format('YYYY-MM-DD');

    const tacticalStart = moment(startOfForecast).subtract(30, 'years').format('YYYY-MM-DD');
    const tacticalEnd = moment(new Date(forecastYear - 1, endOfForecast.month(), endOfForecast.day())).format('YYYY-MM-DD');

    const username = getUserName();

    return JSON.parse(
      JSON.stringify(workflowTemplate)
        .replace(/\{streamid_prefix\}/ig, ['pasture_api', siteName, this.farmingSystem.value, longitude, latitude].join('.').replace(/[\s@]/g, '_'))
        .replace(/\{site_name\}/ig, siteName)
        .replace(/\{longitude\}/ig, longitude)
        .replace(/\{latitude\}/ig, latitude)
        .replace(/\{farming_system\}/ig, this.farmingSystem.value)
        .replace(/\{forecast_year\}/ig, forecastYear)
        .replace(/\{historic_start\}/ig, historicStart)
        .replace(/\{historic_end\}/ig, historicEnd)
        .replace(/\{tactical_start\}/ig, tacticalStart)
        .replace(/\{tactical_end\}/ig, tacticalEnd)
        .replace(/\{username\}/ig, username),
    );
  }

  searchStreamId = (workflow, nodeId) => {
    const searchNode = workflow.graph.nodes.filter(node => node.id === nodeId);
    if (searchNode.length !== 1) {
      return null;
    }

    return searchNode[0].streamid;
  }

  searchDocumentId = (workflow, nodeId) => {
    const searchNode = workflow.graph.nodes.filter(node => node.id === nodeId);
    if (searchNode.length !== 1) {
      return null;
    }

    return searchNode[0].documentid;
  }

  replaceNamesInVectorStream = (vectorStreamTemplate, streamId, reportingPeriod) => JSON.parse(
    JSON.stringify(vectorStreamTemplate)
      .replace(/\{vector_stream_id\}/ig, streamId)
      .replace(/\{reporting_period\}/ig, reportingPeriod),
  );

  replaceNamesInScalarStream = (scalarStreamTemplate, streamId) => JSON.parse(
    JSON.stringify(scalarStreamTemplate)
      .replace(/\{scalar_stream_id\}/ig, streamId),
  );

  replaceNamesInDocument = (documentTemplate, documentId) => JSON.parse(
    JSON.stringify(documentTemplate)
      .replace(/\{document_id\}/ig, documentId),
  );

  replaceNamesInJob = (jobTemplate, newSiteId) => JSON.parse(
    JSON.stringify(jobTemplate)
      .replace(/\{workflow_id\}/ig, newSiteId),
  );

  replaceNamesInSchedule = (
    scheduleTemplate,
    newSiteName,
    newSiteDescription,
    newSiteId,
  ) => JSON.parse(
    JSON.stringify(scheduleTemplate)
      .replace(/\{site_name\}/ig, newSiteName)
      .replace(/\{description\}/ig, newSiteDescription)
      .replace(/\{workflow_id\}/ig, newSiteId),
  );

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({
      isSubmitting: true,
    });

    const {
      id,
      siteName,
      longitude,
      latitude,
    } = this.state;

    const {
      getSitesConnect,
      saveSiteConnect,
      runSiteConnect,
      createScheduleConnect,
    } = this.props;

    if (siteName === '' || longitude === '' || latitude === '') return;

    const workflow = this.replaceNamesInWorkflow(WORKFLOW_TEMPLATE, siteName, longitude, latitude);

    if (id) {
      workflow.id = id;
    }

    try {
      MAPPINGS.forEach(async (mapping) => {
        const { variable, mode, type } = mapping;

        const {
          saveStreamConnect,
          saveDocumentConnect,
        } = this.props;

        if (type === 'percentiles') {
          const streamId = this.searchStreamId(workflow, `${variable}.${mode}.${type}_node`);
          const vectorStream = this.replaceNamesInVectorStream(VECTOR_STREAM_TEMPLATE, streamId, 'P1Y');
          await saveStreamConnect(streamId, vectorStream);
        }

        if (type === 'actual') {
          const streamId = this.searchStreamId(workflow, `${variable}.${mode}.${type}_node`);
          const scalarStream = this.replaceNamesInScalarStream(SCALAR_STREAM_TEMPLATE, streamId, 'P1Y');
          await saveStreamConnect(streamId, scalarStream);
        }

        if (type === 'cdf') {
          const documentId = this.searchDocumentId(workflow, `${variable}.${mode}.${type}_node`);
          const document = this.replaceNamesInDocument(DOCUMENT_TEMPLATE, documentId);
          await saveDocumentConnect(documentId, document);
        }
      });

      await saveSiteConnect(workflow);

      const { newSite } = await this.props;

      const schedule = await this.replaceNamesInSchedule(
        SCHEDULE_TEMPLATE,
        newSite.name,
        newSite.description,
        newSite.id,
      );
      await createScheduleConnect(schedule);

      const job = await this.replaceNamesInJob(
        JOB_TEMPLATE,
        newSite.id,
      );

      await runSiteConnect(job);

      // notification needs an error handler.
      await toastr.success('The new site forecast has been submitted', `${newSite.name} submitted successfully`);

      await this.setState({
        siteName: '',
        longitude: '',
        latitude: '',
        isSubmitting: false,
      });
    } catch (error) {
      await toastr.error('The site failed to install');

      await this.setState({
        isSubmitting: false,
      });
    }

    await getSitesConnect();
  }

  render() {
    const {
      id,
      siteName,
      longitude,
      latitude,
      isSubmitting,
    } = this.state;

    return (
      <SiteCreateWrapper>
        <div className="card mb-3">
          <div className="card-header">
            Create New Site
          </div>
          <div className="card-body">
            <form onSubmit={this.handleSubmit}>
              <input id="siteId" className="form-control" type="hidden" name="siteId" onChange={this.handleChange} value={id} required />
              <div className="form-group row">
                <label htmlFor="siteName" className="col-sm-2 col-form-label">Site Name</label>
                <div className="col-sm-10">
                  <input id="siteName" className="form-control" type="text" name="siteName" placeholder="Please enter a unique name for your site" onChange={this.handleChange} value={siteName} required />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="longitude" className="col-sm-2 col-form-label">Longitude</label>
                <div className="col-sm-3">
                  <input id="longitude" className="form-control" type="text" name="longitude" placeholder="longitude" onChange={this.handleChange} value={longitude} required />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="latitude" className="col-sm-2 col-form-label">Latitude</label>
                <div className="col-sm-3">
                  <input id="latitude" className="form-control" type="text" name="latitude" placeholder="latitude" onChange={this.handleChange} value={latitude} required />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="farmingSystem" className="col-sm-2 col-form-label">Farming System</label>
                <div className="col-sm-10">
                  <select id="farmingSystem" className="form-control" ref={(input) => { this.farmingSystem = input; }} required>
                    <option value="">Please select a representative farming system for your site</option>
                    {AVAILABLE_FARM_SYSTEMS.map(fs => <option key={fs.value} value={fs.value}>{fs.label}</option>)}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-2" />
                <div className="col-sm-10">
                  {isSubmitting ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                      &nbsp;Submitting...
                    </button>
                  ) : (
                    <button className="btn btn-success" type="submit">Submit</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </SiteCreateWrapper>
    );
  }
}

const mapStateToProps = state => ({
  newStream: state.sites.newStream,
  newDocument: state.sites.newDocument,
  newSite: state.sites.newSite,
  newJob: state.sites.newJob,
  newSchedule: state.sites.newSchedule,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getSitesConnect: getSites,
  saveStreamConnect: saveStream,
  saveDocumentConnect: saveDocument,
  saveSiteConnect: saveSite,
  runSiteConnect: runSite,
  createScheduleConnect: createSchedule,
}, dispatch);

SiteCreate.propTypes = {
  getSitesConnect: PropTypes.func.isRequired,
  saveStreamConnect: PropTypes.func.isRequired,
  saveDocumentConnect: PropTypes.func.isRequired,
  saveSiteConnect: PropTypes.func.isRequired,
  runSiteConnect: PropTypes.func.isRequired,
  createScheduleConnect: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteCreate);
