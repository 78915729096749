import React from 'react';

import SitesList from '../components/site-all/SitesList';
import SiteCreate from '../components/site-all/SiteCreate';

const SiteAll = () => (
  <div>
    <SiteCreate />
    <SitesList />
  </div>
);

export default SiteAll;
