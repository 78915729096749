import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/Login';

const UnauthorizedLayout = () => (
  <div>
    <Switch>
      <Route exact path="/auth/login" component={Login} />
      <Redirect to="/auth/login" />
    </Switch>
  </div>
);

export default UnauthorizedLayout;
